/* Gallery.css */

.gallery-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Hide overflow */
}

.gallery-background {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #ff7e5f, #feb47b); /* Flashy gradient background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.gallery-scroll {
    display: flex;
    flex-direction: row;
    height: 100%;
    scroll-behavior: smooth; /* Smooth scrolling */
    overflow-x: hidden; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Hide vertical scroll bar */
}

.gallery-item {
    flex: 0 0 100vw; /* Each item takes up full viewport width */
    height: 100vh; /* Each item takes up full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8); /* Optional: background color */
}

.gallery-image {
    width: 100%; /* Fill the width of the container */
    height: 100%; /* Fill the height of the container */
    object-fit: cover; /* Ensure the image covers the container without distortion */
}

.scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: black;
    border: none;
    padding: 1rem;
    font-size: 2rem;
    cursor: pointer;
    z-index: 10;
}

.scroll-button.left {
    left: 1rem;
}

.scroll-button.right {
    right: 1rem;
}
