
@keyframes backgroundTransition {
    0% {
      background-image: url('./background/1.png');
    }
    50% {
        background-image: url('./background/2.png');
    }
    75% {
        background-image: url('./background/3.png');
    }
    100%{
        background-image: url('./background/1.png');
    }
  }
  
  .background-transition {
    animation: backgroundTransition 10s ease-in-out infinite ;
    background-size: cover;
    background-position: center;
  }
  